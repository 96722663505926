@font-face {
  font-family: 'HelveticaLTStd';
  src: url('fonts/HelveticaLTStd-Light.otf');
}
@font-face {
  font-family: 'HelveticaLTStd';
  src: url('fonts/HelveticaLTStd-Bold.otf');
  font-weight: 700;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'HelveticaLTStd', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position : fixed;
  width : 100%;
  height : 100%;
  display : flex;
  flex-direction: column;
}

/* @media screen and (max-width: 900px) {
  html {font-size: 38.5%;}
}

@media screen and (min-width: 900px) and (max-width: 912px) {
  html {font-size: 39.1%;}
}

@media screen and (min-width: 912px) and (max-width: 924px) {
  html {font-size: 39.6%;}
}

@media screen and (min-width: 924px) and (max-width: 936px) {
  html {font-size: 40.1%;}
}

@media screen and (min-width: 936px) and (max-width: 948px) {
  html {font-size: 40.6%;}
}

@media screen and (min-width: 948px) and (max-width: 960px) {
  html {font-size: 41.1%;}
}

@media screen and (min-width: 960px) and (max-width: 972px) {
  html {font-size: 41.7%;}
}

@media screen and (min-width: 972px) and (max-width: 984px) {
  html {font-size: 42.2%;}
}

@media screen and (min-width: 984px) and (max-width: 996px) {
  html {font-size: 42.7%;}
}

@media screen and (min-width: 996px) and (max-width: 1008px) {
  html {font-size: 43.2%;}
}

@media screen and (min-width: 1008px) and (max-width: 1020px) {
  html {font-size: 43.8%;}
}

@media screen and (min-width: 1020px) and (max-width: 1032px) {
  html {font-size: 44.3%;}
}

@media screen and (min-width: 1032px) and (max-width: 1044px) {
  html {font-size: 44.8%;}
}

@media screen and (min-width: 1044px) and (max-width: 1056px) {
  html {font-size: 45.3%;}
}

@media screen and (min-width: 1056px) and (max-width: 1068px) {
  html {font-size: 45.8%;}
}

@media screen and (min-width: 1068px) and (max-width: 1080px) {
  html {font-size: 46.4%;}
}

@media screen and (min-width: 1080px) and (max-width: 1092px) {
  html {font-size: 46.9%;}
}

@media screen and (min-width: 1092px) and (max-width: 1104px) {
  html {font-size: 47.4%;}
}

@media screen and (min-width: 1104px) and (max-width: 1116px) {
  html {font-size: 47.9%;}
}

@media screen and (min-width: 1116px) and (max-width: 1128px) {
  html {font-size: 48.4%;}
}

@media screen and (min-width: 1128px) and (max-width: 1140px) {
  html {font-size: 49%;}
}

@media screen and (min-width: 1140px) and (max-width: 1152px) {
  html {font-size: 49.5%;}
}

@media screen and (min-width: 1152px) and (max-width: 1164px) {
  html {font-size: 50%;}
}

@media screen and (min-width: 1164px) and (max-width: 1176px) {
  html {font-size: 50.5%;}
}

@media screen and (min-width: 1176px) and (max-width: 1188px) {
  html {font-size: 51%;}
}

@media screen and (min-width: 1188px) and (max-width: 1200px) {
  html {font-size: 51.6%;}
}

@media screen and (min-width: 1200px) and (max-width: 1212px) {
  html {font-size: 52.1%;}
}

@media screen and (min-width: 1212px) and (max-width: 1224px) {
  html {font-size: 52.6%;}
}

@media screen and (min-width: 1224px) and (max-width: 1236px) {
  html {font-size: 53.1%;}
}

@media screen and (min-width: 1236px) and (max-width: 1248px) {
  html {font-size: 53.6%;}
}

@media screen and (min-width: 1248px) and (max-width: 1260px) {
  html {font-size: 54.2%;}
}

@media screen and (min-width: 1260px) and (max-width: 1272px) {
  html {font-size: 54.7%;}
}

@media screen and (min-width: 1272px) and (max-width: 1284px) {
  html {font-size: 55.2%;}
}

@media screen and (min-width: 1284px) and (max-width: 1296px) {
  html {font-size: 55.7%;}
}

@media screen and (min-width: 1296px) and (max-width: 1308px) {
  html {font-size: 56.3%;}
}

@media screen and (min-width: 1308px) and (max-width: 1320px) {
  html {font-size: 56.8%;}
}

@media screen and (min-width: 1320px) and (max-width: 1332px) {
  html {font-size: 57.3%;}
}

@media screen and (min-width: 1332px) and (max-width: 1344px) {
  html {font-size: 57.8%;}
}

@media screen and (min-width: 1344px) and (max-width: 1356px) {
  html {font-size: 58.3%;}
}

@media screen and (min-width: 1356px) and (max-width: 1368px) {
  html {font-size: 58.9%;}
}

@media screen and (min-width: 1368px) and (max-width: 1380px) {
  html {font-size: 59.4%;}
}

@media screen and (min-width: 1380px) and (max-width: 1392px) {
  html {font-size: 59.9%;}
}

@media screen and (min-width: 1392px) and (max-width: 1404px) {
  html {font-size: 60.4%;}
}

@media screen and (min-width: 1404px) and (max-width: 1416px) {
  html {font-size: 60.9%;}
}

@media screen and (min-width: 1416px) and (max-width: 1428px) {
  html {font-size: 61.5%;}
}

@media screen and (min-width: 1428px) and (max-width: 1440px) {
  html {font-size: 62%;}
}

@media screen and (min-width: 1440px) and (max-width: 1452px) {
  html {font-size: 62.5%;}
}

@media screen and (min-width: 1452px) and (max-width: 1464px) {
  html {font-size: 63%;}
}

@media screen and (min-width: 1464px) and (max-width: 1476px) {
  html {font-size: 63.5%;}
}

@media screen and (min-width: 1476px) {
  html {font-size: 64.1%;}
} */
